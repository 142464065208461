<template>
  <y_layout>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="basic">
        <template #title>
          <div style="padding-bottom: 1rem;color: #888888;font-size: 1.2rem">
            基础功能
          </div>
        </template>
        <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)" class="flex-def" style="width: 100%;min-height: 40rem">
          <el-row v-if="mode==='list'" :gutter="40" style="width: 100%">
            <el-col v-for="(item,key) in list" :key="key" :span="6">
              <div class="y-addons-item flex-def">
                <el-image v-if="item.pic" :src="item.pic.url"
                          style="width: 5rem;height: 5rem;border-radius: .5rem"></el-image>
                <div style="margin-left: .5rem;width: calc(100% - 6.5rem)">
                  <div style="font-size: 1.2rem;">{{ item.title }}
                    <span class="tag" v-if="item.tag">{{ item.tag }}</span>
                  </div>
                  <div v-html="item.desc" class="text-ellipsis3" style="height: 3.5rem;font-size: .8rem;color: #888888"></div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div v-if="mode==='empty'" class="flex-zCenter flex-cCenter flex-def"
               style="height: 20rem;width: 100%;font-size: 2rem;font-weight: 600;color: #e0dddd">
            待开放
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item name="added">
        <template #title>
          <div style="padding: 1rem 0;color: #888888;font-size: 1.2rem">
            增值插件
          </div>
        </template>
        <div v-loading="addedLoading" element-loading-background="rgba(0, 0, 0, 0)" class="flex-def" style="width: 100%;min-height: 40rem">
          <el-row :gutter="40" style="width: 100%">
            <el-col v-for="(item,key) in addedList" :key="key" :span="6">
              <div class="y-addons-item flex-def">
                <el-image :src="elIcon(item.plugin)"
                          style="width: 5rem;height: 5rem;border-radius: .5rem"></el-image>
                <div style="margin-left: .5rem;width: calc(100% - 6.5rem);position: relative;  min-width: 10rem;">
                  <div style="font-size: 1.2rem;">{{ item.title }}
                    <span class="tag" v-if="item.tag">{{ item.tag }}</span>
                  </div>
                  <div v-html="item.desc" class="text-ellipsis1" style="height: .8rem;font-size: .8rem;color: #888888"></div>
                  <div class="flex-def flex-cCenter flex-zBetween">
                    <div><el-button v-if="item.page" @click="$router.push({
                    name:item.page,
                    params:$route.params
                    })" type="text">进入插件</el-button></div>
                    <div>
                      <el-switch
                          @change="addedChange($event,item)"
                          v-model="item.power"
                          active-color="#13ce66"
                          inactive-color="#ff4949">
                      </el-switch>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {y_layout},
  data() {
    return {
      list: [],
      loading:false,
      mode:"",
      activeName:"added",
      addedLoading:false,
      addedList:[]
    }
  },
  mounted() {
    this.load();
    this.loadAdded();
  },
  methods: {
    ...mapActions("addons",["reload"]),
    addedChange(value,item){
      this.$api.addons.addedChange(item).then(()=>{
        this.loadAdded();
        if (["ivideo","shop"].includes(item.plugin))location.reload();
      }).catch(()=>{
        this.addedList.forEach((v,k)=>{
          if (v.plugin === item.plugin){
            this.addedList[k].power = !value;
          }
        })
      })
    },
    elIcon(name) {
      return `./${process.env.BASE_URL}icon/addons/${name}.png`
    },
    loadAdded(){
      this.addedLoading = true;
      this.$api.addons.added().then(res=>{
        this.addedLoading = false;
        this.addedList = res;
        this.reload();
      })
    },
    load() {
      this.loading = true;
      this.$api.addons.basic().then(res => {
        this.loading = false;
        res.forEach(item => {
          item.desc = item.desc.replace("\\n", "<br>")
        })
        this.list = res;

        if(res.length){
          this.mode = "list";
        }else {
          this.mode = "empty"
        }
      })
    },
  }
}
</script>

<style>
.el-collapse-item__wrap{
  background-color: #f8f8f8;
}
.el-collapse-item__header{
  background-color: #f8f8f8;
}
.el-collapse{
  border-top: unset;
}
</style>
<style scoped>
.y-addons-item {
  background-color: #ededed;
  padding: .5rem;
  width: 100%;
  margin-bottom: 1rem;
  height: 5rem;
  overflow: hidden;
}

.tag {
  font-size: 1rem;
  color: red;
  font-weight: 600;
  font-style: oblique;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 2px 2px 3px 1px #f3fd74;
  border-radius: 2rem;
}

</style>